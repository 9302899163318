import PaymentConfirmation from "@/components/PaymentConfirmation/PaymentConfirmation.vue";
import CardService from "../../../../services/CardService";

export default {
  components: {
    PaymentConfirmation: PaymentConfirmation
  },
  data() {
    return {
      cardService: new CardService(),
      cards: [],
      cardId: "",
      deleteClicked: false,
      showLoading: true
    };
  },
  async mounted() {
    this.cards = await this.cardService.getCards().catch(() => {
      this.showLoading = false;
    });
    this.showLoading = false;
  },
  methods: {
    async deleteCard(cardId) {
      this.cardId = cardId;
      this.deleteClicked = false;
    },
    async confirmDelete() {
      await this.cardService.deleteCard(this.cardId);
      this.cards = await this.cardService.getCards();
      this.deleteClicked = false;
      this.$toaster.success(this.$t("GLOBAL.SUCCESS"));
    }
  }
};
