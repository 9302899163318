export default {
  name: "PaymentConfirmation",
  props: {
    text: {
      type: String,
      default: ""
    },
    personName: {
      type: String,
      default: ""
    },
    isCheckout: {
      type: Boolean,
      default: false
    }
  },
  created() {
    let self = this;
    setTimeout(function() {
      self.$router.push("/payment/history");
    }, 3000);
  }
};
